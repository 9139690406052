import React, { useState } from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { useLanguage } from "../context/LanguageContext";
import { translate } from "../utils/translation";

function ContactPage() {
  const { language } = useLanguage() || { language: "en" }; // Fallback to "en" if language is undefined
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
  });
  const [status, setStatus] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus(translate("contact.sending", language));
    try {
      const response = await fetch("https://api-beta.macedoniadelnorte.com/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          message: formData.message,
        }),
      });
      if (response.ok) {
        setStatus(translate("contact.success", language));
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          message: "",
        });
      } else {
        setStatus(translate("contact.failure", language));
      }
    } catch (error) {
      setStatus(translate("contact.failure", language));
    }
  };

  return (
    <Layout>
      <SEO
        keywords={translate("contact.keywords", language)}
        title={translate("contact.title", language)}
      />
      <section className="max-w-4xl mx-auto p-8">
        <h1 className="text-4xl mb-8 font-extrabold text-center text-gray-800">
          {translate("contact.heading", language)}
        </h1>
        <p className="mb-10 text-lg leading-loose text-center text-gray-600">
          {translate("contact.intro", language)}
        </p>

        <form
          onSubmit={handleSubmit}
          className="bg-white shadow-lg rounded-lg p-8"
        >
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            <div>
              <label
                className="block text-gray-700 text-sm font-semibold mb-2"
                htmlFor="firstName"
              >
                {translate("contact.firstNameLabel", language)}
              </label>
              <input
                className="shadow-sm appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
                id="firstName"
                type="text"
                name="firstName"
                placeholder={translate(
                  "contact.firstNamePlaceholder",
                  language
                )}
                value={formData.firstName}
                onChange={handleChange}
                required
              />
            </div>

            <div>
              <label
                className="block text-gray-700 text-sm font-semibold mb-2"
                htmlFor="lastName"
              >
                {translate("contact.lastNameLabel", language)}
              </label>
              <input
                className="shadow-sm appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
                id="lastName"
                type="text"
                name="lastName"
                placeholder={translate("contact.lastNamePlaceholder", language)}
                value={formData.lastName}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="mb-6">
            <label
              className="block text-gray-700 text-sm font-semibold mb-2"
              htmlFor="email"
            >
              {translate("contact.emailLabel", language)}
            </label>
            <input
              className="shadow-sm appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
              id="email"
              type="email"
              name="email"
              placeholder={translate("contact.emailPlaceholder", language)}
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>

          <div className="mb-8">
            <label
              className="block text-gray-700 text-sm font-semibold mb-2"
              htmlFor="message"
            >
              {translate("contact.messageLabel", language)}
            </label>
            <textarea
              className="shadow-sm appearance-none border rounded w-full py-3 px-4 text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-blue-500"
              id="message"
              name="message"
              placeholder={translate("contact.messagePlaceholder", language)}
              rows="6"
              value={formData.message}
              onChange={handleChange}
              required
            />
          </div>

          <div className="flex justify-center">
            <button
              className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-8 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500"
              type="submit"
            >
              {translate("contact.submitButton", language)}
            </button>
          </div>

          {status && (
            <p className="mt-6 text-center text-lg text-gray-600">{status}</p>
          )}
        </form>
      </section>
    </Layout>
  );
}

export default ContactPage;
